import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Toast, Row, Col, Alert } from 'react-bootstrap'
import { cap,pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import patern from '../assets/img/kristi/3773.svg'
import AOS from 'aos';
import { gambar } from '../params'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import post from '../params/post'


let cmain = '#fce7d2ff'
let mainfont = '#AD3243'
let csub = " #E3B62D"
let black = 'rgb(38,38,38)'

let id = 'vidella-edu'
let inisial_co = 'Eduart'
let inisial_ce = 'Vidella'
let lengkap_co = 'Eduart Octavianus Sembiring , ST'
let lengkap_ce = 'Ellienz Vidella Tarigan , S.Psi'
let bapak_co = 'Bpk. AKBP (P) Semangat Waja Sembiring, SH'
let ibu_co = 'Ibu Firmina Purba , BSC'
let bapak_ce = "Bpk. Pt. Em. Ir. Semion Tarigan "
let ibu_ce = "Ibu Sempa Malem Barus, AMD. Keb."
let ig_co = "eduartsembiring"
let ig_ce = "videllatarigan"
let gcalendar="https://goo.gl/maps/DNynYJ3RA6xhkwWw6"

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2,
            showgift: false,
            show: false,
            show1: false,
            show2: false
        }
    }
    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date("2021", "0", "16", "12").getTime();
        // Update the count down every 1 second
        var x = setInterval(() => {
            // Get today's date and time
            var now = new Date().getTime();
            // Find the distance between now and the count down date
            var distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })
        }, 1000);
    }
    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showgift, show, show1, show2 } = this.state
        let slide = [
            "DSC05309.jpg",
            "DSC05357.jpg",
            "DSC05468.jpg",
            "DSC05604.jpg",
            "DSC09384.jpg",
            "DSC09416.jpg",
            "DSC09497.jpg",
            "DSC09563.jpg",
            "DSC09564.jpg",
            "DSC09611.jpg",
        ]
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id,v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
        })
        let query = this.useQuery().get('name');
        let time = this.useQuery().get('time');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: cmain,
                    backgroundImage: `url('https://svgshare.com/i/RoG.svg')`,
                    backgroundSize: '25%'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${pw(id, "modal.jpg")}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={pw(id, "logo.png")} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        <br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button roboto-slab text-center btn ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                  </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                   
                                    <Container id='sectiongold57'>
                                        <div className='py-3'>
                                            <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                                <iframe className="embed-responsive-item"
                                                    src="https://www.youtube.com/embed/cXj4-BSdD_o"></iframe>
                                            </div>
                                            <Item>


                                                <div data-aos="fade-left" data-aos-duration="1000"
                                                    className='col-10 col-lg-8 kotak' style={{ backgroundColor: csub }}>
                                                    <Item>
                                                        <div className='item'>
                                                            <Item>
                                                                <div>
                                                                    {days}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Days
                                    </span>
                                                            </Item>
                                                        </div>
                                                        <div className='dot'>:</div>
                                                        <div className='item'>
                                                            <Item>
                                                                <div>
                                                                    {hours}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Hours
                                      </span>
                                                            </Item>
                                                        </div>
                                                        <div className='dot'>:</div>
                                                        <div className='item'>
                                                            <Item>
                                                                <div >
                                                                    {minutes}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Mins
                                      </span>
                                                            </Item>
                                                        </div>
                                                        <div className='dot' >:</div>
                                                        <div className='item'>
                                                            <Item>
                                                                <div>
                                                                    {seconds}
                                                                </div>
                                                            </Item>
                                                            <Item>
                                                                <span>
                                                                    Secs
                                      </span>
                                                            </Item>
                                                        </div>
                                                    </Item>


                                                </div>

                                            </Item>
                                        </div>
                                    </Container>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: '#86835d' }}>
                                            The Intimate Wedding of {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >
                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' style={{ backgroundImage: `url('https://rbmg.fr/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-9.png')` }}>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={pw(id, "ce.jpg")} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '36px', fontFamily: "'Marck Script', cursive",
                                                        color: mainfont
                                                    }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                                                        <b>The Daughter of: </b><br />
                                                        {bapak_ce}<br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='img-fluid w-100 btn p-0'
                                                    style={{width:'35px',height:'35px'}}
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} />
                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' style={{ backgroundImage: `url('https://rbmg.fr/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-9.png')` }}>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {inisial_co}
                      </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={pw(id,"co.jpg")} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{ fontSize: '36px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {lengkap_co}
                      </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                                                        <b>The Son of : </b><br />
                           {bapak_co}
                        <br />
                        &<br />
                       {ibu_co}
                      </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='img-fluid w-100 btn p-0'
                                                    style={{width:'35px',height:'35px'}}
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} />
                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: '#86835d' }} >
                                    <Item>
                                        <p className="fs16">
                                            The wedding will be held on :
                    </p>
                                    </Item>
                                    
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: '#86835d' }}>
                                            <b className="allura" style={{ fontSize: '46px' }}>Holy Matrimony</b><br />
                                            <Item>
                                        <p className="fs20">
                                            <b>
                                                Friday <span className="fs36">15</span> JAN 2021
                      </b>
                                        </p>
                                    </Item>
                                            <div className="py-3">
                                            15.00 WIB - finish
                       </div>
                                            <br />
                                            <span className=" fs16">
                                                <b>
                                                GBKP Bangun Mulia, <br />
                                                </b>
                                                Jalan Sisingamangaraja Km.11, Kecamatan Medan Amplas, Medan
                                                </span>
                                        </p>
                                        <p className="px-3 d-none d-sm-block" style={{ color: csub, fontSize: '72px' }}>
                                            \
                    </p>
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid #86835d` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: '#86835d' }}>
                                            <b className="allura" style={{ fontSize: '46px' }}> 
                                            Intimate Reception</b><br />
                                            <Item>
                                        <p className="fs20">
                                            <b>
                                                Saturday <span className="fs36">16</span> JAN 2021
                      </b>
                                        </p>
                                    </Item>
                                            <div className="py-3">
                                            12.00 - 15.00 WIB </div>
                                            <br />
                                            <span className=" fs16">
                                                <b>
                                                BALAI NAMAKEN, 
                    </b> <br />
                    Jalan Jamin Ginting, pasar VII, kecamatan medan selayang, Medan
                    </span>
                                        </p>
                                    </Item>
                                    <Item>
                                       
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid #86835d`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn ">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: 'auto' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: '#86835d' }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                <Container className="text-center dinny py-3" >
                                    <Item>
                                        <Col className="py-3 px-sm-5" style={{ border: `2px solid #86835d`, borderRadius: '10px', fontFamily: 'Josefin Sans, sans-serif' }}>
                                            <h1 className="fs30"
                                                style={{

                                                    fontFamily: 'Adine-Kirnberg-Alternate',
                                                    color: '#86835d',
                                                    fontSize: '36px',
                                                    fontWeight: 700
                                                }}>
                                                Special Conditions<br />
For our Safety and Health


                          </h1>
                                            <Item>
                                                <Col xs={12}>
                                                    <p className="cblack text-center w-100 fs16">
                                                        Regarding this current COVID-19 pandemic, in order to maintain physical distancing that is currently being applied, unfortunately, we are only welcoming the invited guest. Thank you for your understanding.
                          </p>
                                                </Col>
                                                <Col xs={6} sm={3}>
                                                    <img src={pw("reni-shidqi", 'mask.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            ALWAYS PUT<br />
                                                        ON A MASK
                        </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={3}>
                                                    <img src={pw("reni-shidqi", 'hand.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            ALWAYS USE<br />HANDSANITIZER
                      </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={3}>
                                                    <img src={pw("reni-shidqi", 'distance.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            PHYSICAL<br />DISTANCING
                      </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={3}>
                                                    <img src={pw("reni-shidqi", 'salaman.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            DON’T SHAKING<br />HAND
                      </p>
                                                    </Row>
                                                </Col>
                                            </Item>
                                        </Col>
                                    </Item>

                                    <Item>
                                        <Col>
                                        </Col>

                                    </Item>

                                </Container>


                               
                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Item>
                                        <div className="butterfly">
                                            <h1 style={{ color: '#86835d', fontStyle: 'bold', fontWeight: 700, fontSize: '72px' }}>
                                                The Couple
                        </h1>
                                        </div>
                                    </Item>
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4' style={{ color: '#86835d' }}>
                                                        When two souls fall in love, there is nothing else but the yearning to be close to the other. The presence that is felt through a hand held, a voice heard, or a smile seen.
<br/><br/>
Souls do not have calendars or clocks, nor do they understand the notion of time or distance. They only know it feels rights to be with one another.
<br/><br/>
- Lang Leav -
                                                            </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>
                                {/* <Container id='sectiongold58' >
                  <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                    <Item>
                      <Col xs={4} lg={2}>
                        <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                      <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                        <Item>
                          <h1 style={{
                            fontFamily: '"Marck Script", cursive',
                            color: mainfont
                          }}>
                            Send Your Wishes
                            </h1>
                        </Item>
                        <Item>
                          <form className="col-12 w-100">
                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                            <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1" style={{color:mainfont}}>Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"
                                    style={{color:mainfont}}
                                    >Tidak Hadir</label>
                                  </div>

                                </div>
                              </div>
                            </Item>
                            
                            <Item>
                              <Col xs={12} className=''>
                                {
                                  submitted == true ? (
                                    <Alert variant='success' style={{fontSize:'16px'}}>
                                      Pesan anda sudah disampaikan
                                    </Alert>) : (submitted === false ? (
                                      <Alert variant='danger' style={{fontSize:'16px'}}>
                                        {
                                          err.map(val => {
                                            return (
                                              <li>{val}</li>
                                            )
                                          })
                                        }

                                      </Alert>
                                    ) : false)
                                }

                              </Col>
                            </Item>
                            <Item>
                              <button type='button' className='col-6 button rounded'
                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain }} no> Kirim </button>
                            </Item>
                          </form>
                        </Item>
                      </div>
                    </Item>
                  </div>
                </Container>
                 */}
                                <Container className="py-3" fluid >
                                    <Item>
                                        <Col
                                            onClick={() => this.setState({ showgift: !showgift })}
                                            xs={10} md={4}
                                            style={{
                                                border: `2px solid #86835d`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-md-2 mt-3 mt-md-0">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: '#86835d' }}>
                                                    <b>Send Gift (click here)</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                <Container fluid
                                    className={`text-center mt-3 aulia-wedding py-3 px-3 ${!showgift ? 'trsnone' : 'trs'}`} >
                                    <Col className="py-3 px-md-5 mx-2 w-100 text-center" style={{ backgroundColor: cmain, borderRadius: '10px', color: '#86835d' }}>
                                        <h1 style={{ color: '#86835d' }} className="w-100 text-center">
                                            Wedding Gift
                      </h1>
                      <p className="text-center w-100" style={{ color: 'black', fontSize:'16px' }}>
                      Terima kasih atas segala bentuk keterlibatan Bapak/Ibu/Saudara/i dalam mendukung pernikahan kami. Doa dan restu dari Bapak/Ibu/Saudara/i jauh lebih berarti bagi kebahagiaan pernikahan kami.
<br/><br/>
Sekiranya terdapat hadiah pernikahan yang hendak diberikan kepada kami, berikut informasi nomor akun yang dapat digunakan :
                      </p>
                                        <p className="text-center w-100" style={{ color: '#86835d' }}>
                                        
 
BCA - 8430269381  <br />
(Ellienz Vidella)
                          </p>
                          
                                        <Item>
                                            <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                                                <Toast.Body>Copied!</Toast.Body>
                                            </Toast>
                                        </Item>
                                        <Item>
                                            <CopyToClipboard text="8430269381">
                                                <Col xs={8} className='btn button mb-5 mt-1'
                                                    onClick={(e) => {
                                                        this.setState({ show: true })
                                                    }}>
                                                    Salin No. Rekening
                          </Col>
                                            </CopyToClipboard>
                                        </Item>
                                        <p className="text-center w-100" style={{ color: '#86835d' }}>
                                        BNI - 0149391244<br/>
(Eduart Octavianus Sembiring) 
                          </p>
                                        <Item>
                                            <Toast onClose={() => this.setState({ show1: false })} show={this.state.show1} delay={3000} autohide >
                                                <Toast.Body>Copied!</Toast.Body>
                                            </Toast>
                                        </Item>

                                        <Item>
                                            <CopyToClipboard text="0149391244">
                                                <Col xs={8} className='btn button mb-5 mt-1'

                                                    onClick={(e) => {
                                                        this.setState({ show1: true })
                                                    }}>
                                                    Salin No. Rekening
                          </Col>
                                            </CopyToClipboard>
                                        </Item>
                                        <p className="text-center w-100" style={{ color: '#86835d' }}>
                                        Mandiri - 106.00.9002646.2<br/>
(Semion Tarigan)
                          </p>
                                        <Item>
                                            <Toast onClose={() => this.setState({ show2: false })} show={this.state.show2} delay={3000} autohide >
                                                <Toast.Body>Copied!</Toast.Body>
                                            </Toast>
                                        </Item>
                                        <Item>
                                            <CopyToClipboard text="1060090026462">
                                                <Col xs={8} className='btn button mb-5 mt-1'

                                                    onClick={(e) => {
                                                        this.setState({ show2: true })
                                                    }}>
                                                    Salin No. Rekening
                          </Col>
                                            </CopyToClipboard>
                                        </Item>
                                       
                                    </Col>


                                    <p className="text-center w-100" style={{ color: 'black', fontSize:'16px' }}>
                                    Mohon tambahkan angka 1 pada digit terakhir nominal pemberian untuk memudahkan kami dalam pendataan.
<br/>Contoh : Rp.10.001,-
<br/><br/>
Terima kasih,<br/>
Tuhan Memberkati 😇
                      </p>
                                </Container>
                                <Container id='sectiongold58' >

<div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
    
    <Item>
        <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
            <Item>
                <h1 className="w-100 text-center" style={{
                    fontFamily: '"Marck Script", cursive',
                    color: '#86835d'
                }}>
                    Send Your Wishes
</h1>
            </Item>
            <Item>
                <form className="col-12 w-100">
                    <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                    <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                    <Item>
                        <div id="formradio">
                            <div class="custom_radio row justify-content-center">
                                <div onClick={() => {
                                    this.setState({ hadir: true })
                                }
                                }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1">Attend</label>
                                </div>
                                <div onClick={() => {
                                    this.setState({ hadir: false })
                                }
                                } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"

                                    >Not Attend</label>
                                </div>
                                

                            </div>
                        </div>
                    </Item>

                    <Item>
                        <Col xs={12} className=''>
                            {
                                submitted == true ? (
                                    <Alert variant='success' style={{ fontSize: '16px' }}>
                                        Pesan anda sudah disampaikan
                                    </Alert>) : (submitted === false ? (
                                        <Alert variant='danger' style={{ fontSize: '16px' }}>
                                            {
                                                err.map(val => {
                                                    return (
                                                        <li>{val}</li>
                                                    )
                                                })
                                            }

                                        </Alert>
                                    ) : false)
                            }

                        </Col>
                    </Item>
                    <Item>
                        <div className='col-6 button rounded btn'
                            onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                    </Item>
                </form>
            </Item>
        </div>
    </Item>
</div>
</Container>


                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

